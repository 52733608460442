import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import UserDataTable from '../components/UserDataTable';
import ListView from './List';
import SimpleList from './SimpleList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  if (value !== index) return null;
  return <>{children}</>;
};

interface AdminProps {
  usersView?: boolean;
}

const Admin: React.FC<AdminProps> = ({ usersView }) => {
  const [currentTabIdx, setCurrentTabIdx] = useState<number>(usersView ? 1 : 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTabIdx(newValue);
  };

  return (
    <>
      <Tabs value={currentTabIdx} onChange={handleChange}>
        <Tab label="Formularze" />
        <Tab label="Użytkownicy" />
        <Tab label="Zamawiający" />
        <Tab label="Budowy" />
      </Tabs>
      <TabPanel value={currentTabIdx} index={0}>
        <ListView newLinkTo={'/admin/form/new'} />
      </TabPanel>
      <TabPanel value={currentTabIdx} index={1}>
        <UserDataTable />
      </TabPanel>
      <TabPanel value={currentTabIdx} index={2}>
        <SimpleList itemPath="orderers" />
      </TabPanel>
      <TabPanel value={currentTabIdx} index={3}>
        <SimpleList itemPath="constructions" />
      </TabPanel>
    </>
  );
};

export default Admin;
