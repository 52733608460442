import React from 'react';
import { TextField } from '@material-ui/core';
import { idType } from '../../types/Form';

export interface FormInputProps {
  value: any;
  id: string;
  type: string;
  onChange: (e: any) => void;
  isDisabled?: boolean;
  idToLabel: any;
  error: { [key: string]: boolean };
  multiline?: boolean;
}

const FormTextInput: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  type,
  isDisabled = false,
  idToLabel,
  error,
  multiline,
}) => {
  return (
    <TextField
      fullWidth
      style={{ marginTop: '2rem' }}
      value={value}
      id={id}
      type={type}
      onChange={onChange}
      error={error[id]}
      label={idToLabel[id as idType]}
      disabled={isDisabled}
      variant="outlined"
      multiline={multiline}
      inputProps={{
        step: 0.5,
      }}
    />
  );
};

export default FormTextInput;
