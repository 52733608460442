import { createContext, Dispatch, SetStateAction } from 'react';
import { IUser } from '../types/User';

interface UserContextObject {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>> | null;
}

const initialContext: UserContextObject = {
  user: null,
  setUser: null,
};

const UserContext = createContext(initialContext);

export default UserContext;
