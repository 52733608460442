import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import FormsContext from './context/FormsContext';
import { FormsContextProvider } from './context/FormsContextProvider';
import UserContext from './context/UserContext';
import UsersContext from './context/UsersContext';
import { UsersContextProvider } from './context/UsersContextProvider';
import { IForm } from './types/Form';
import { IUser } from './types/User';
import RouterSwitch from './utils/RouterSwitch';

export interface AppProps {
  [key: string]: string;
}

const App: React.FC<AppProps> = () => {
  const [user, setUser] = useState<IUser | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <UsersContextProvider>
        <FormsContextProvider>
          <Router>
            <Header />
            <RouterSwitch />
          </Router>
        </FormsContextProvider>
      </UsersContextProvider>
    </UserContext.Provider>
  );
};

export default App;
