import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { ExitToApp, Home, VpnKey, DeleteSweep } from '@material-ui/icons';
import UserContext from '../context/UserContext';

export interface DrawerProps {
  open: any;
  close: any;
}

const link = {
  color: 'black',
};

const menuItems = [
  {
    path: '/admin/dashboard',
    Icon: Home,
    label: 'Ekran główny',
    access: ['admin'],
  },
  {
    path: '/form/list',
    Icon: Home,
    label: 'Lista zadań',
    access: ['user'],
  },
  {
    path: '/newPw',
    Icon: VpnKey,
    label: 'Zmień hasło',
    access: ['user', 'admin'],
  },
  {
    path: '/logout',
    Icon: ExitToApp,
    label: 'Wyloguj',
    access: ['admin', 'user'],
  },
];

const DrawerMenu: React.FC<DrawerProps> = ({ open, close }) => {
  const user = React.useContext(UserContext);
  const handleWipeCache = React.useCallback(() => {
    localStorage.removeItem('currentFormState');
    localStorage.removeItem('lastFormState');

    close();
  }, [close]);
  if (!user.user) return null;

  return (
    <Drawer open={open} onClose={close}>
      <List style={{ width: 250 }}>
        {menuItems.map((menuItem) => {
          if (
            user.user &&
            menuItem.access &&
            menuItem.access.includes(user.user.role)
          ) {
            return (
              <Link
                key={menuItem.label}
                to={menuItem.path}
                onClick={close}
                style={{ color: '#333', textDecoration: 'none' }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <menuItem.Icon />
                  </ListItemIcon>
                  <ListItemText>{menuItem.label}</ListItemText>
                </ListItem>
              </Link>
            );
          }
        })}
        <a
          key="menu-wipecache"
          href="/#"
          onClick={handleWipeCache}
          style={{ color: '#333', textDecoration: 'none' }}
        >
          <ListItem button>
            <ListItemIcon>
              <DeleteSweep />
            </ListItemIcon>
            <ListItemText>Wyczyść pamięć</ListItemText>
          </ListItem>
        </a>
      </List>
    </Drawer>
  );
};

export default DrawerMenu;
