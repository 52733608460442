import React, { SetStateAction, Dispatch } from 'react';
import { idType } from '../../types/Form';
import FormDateInput from './FormDateInput';
import FormRoleInput from './FormRoleInput';
import FormTimeInput from './FormTimeInput';
import FormBooleanInput from './FormBooleanInput';
import FormTextInput from './FormTextInput';
import FormWorkerSearch from './FormWorkerSearch';
import FormClientSearch from './FormClientSearch';
import FormSignatureInput from './FormSignatureInput';

//REVIEW enum type?
export interface FormInputProps<T> {
  idToType: any;
  idToLabel: any;
  formState: T;
  setFormState: Dispatch<SetStateAction<T>>;
  value: any;
  id: string;
  errorFields: { [key: string]: boolean };
}

const FormInput = <T extends object, U extends keyof T>({
  idToType,
  idToLabel,
  formState,
  setFormState,
  value,
  id,
  errorFields,
}: FormInputProps<T>) => {
  const onTextInputChange = (e: any) => {
    const prevState = { ...formState };

    prevState[e.target.id as U] = e.target.valueAsNumber || e.target.value;

    console.log('changed state of ' + e.target.id);
    setFormState(prevState);
  };

  switch (idToType[id as idType] as string) {
    case 'date':
      return (
        <FormDateInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'time':
      return (
        <FormTimeInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'boolean':
      return (
        <FormBooleanInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'role':
      return (
        <FormRoleInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'hidden':
      return null;
    case 'worker-search':
      return (
        <FormWorkerSearch
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'client-search':
      return (
        <FormClientSearch
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'signature':
      return (
        <FormSignatureInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
        />
      );
    case 'disabled-signature':
      return (
        <FormSignatureInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          isDisabled={true}
          onChange={onTextInputChange}
        />
      );
    case 'text-multiline':
      return (
        <FormTextInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          multiline
          id={id}
          onChange={onTextInputChange}
          type={idToType[id as idType]}
        />
      );
    case 'disabled':
      return (
        <FormTextInput
          error={errorFields}
          idToLabel={idToLabel}
          value={value}
          id={id}
          onChange={onTextInputChange}
          isDisabled={true}
          type={idToType[id as idType]}
        />
      );
  }

  return (
    <FormTextInput
      error={errorFields}
      idToLabel={idToLabel}
      value={value}
      id={id}
      onChange={onTextInputChange}
      type={idToType[id as idType]}
    />
  );
};

export default FormInput;
