import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import Drawer from './Drawer';
import React, { useContext, useState } from 'react';
import UserContext from '../context/UserContext';

const Header: React.FC = () => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  return (
    <AppBar position="sticky">
      <Drawer open={isDrawerOpen} close={() => setDrawerOpen(false)} />
      <Toolbar>
        <IconButton
          onClick={() => setDrawerOpen(!isDrawerOpen)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6">SzleszMar Pracownik</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
