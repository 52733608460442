import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { idType } from '../../types/Form';
import ApiHandler from '../../utils/ApiHandler';

export interface FormInputProps {
  value: string;
  id: string;
  onChange: (e: any) => any;
  idToLabel: any;
  error: { [key: string]: boolean };
}

const FormClientsearch: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  idToLabel,
  error,
}) => {
  const [clients, setClients] = useState<string[]>([]);

  const handleInput = (e: any, v: any) => {
    const syntheticEvent = {
      target: {
        id: id,
        value: v,
      },
    };
    onChange(syntheticEvent);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ApiHandler.getItems('orderers', 'parsed');
        if (res.success) setClients(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <FormControl variant="outlined" fullWidth style={{ marginTop: '2rem' }}>
      <Autocomplete
        id={id}
        value={value}
        freeSolo
        options={clients}
        onInputChange={handleInput}
        renderInput={(params) => {
          return (
            <TextField
              error={error[id]}
              {...params}
              label={idToLabel[id as idType]}
              variant="outlined"
            />
          );
        }}
      />
    </FormControl>
  );
};

export default FormClientsearch;
