import { IForm, IAdminForm } from '../types/Form';

export interface IValidateSchema {
  [key: string]: {
    required?: boolean;
    min?: number;
    max?: number;
  };
}

export const validateForm = (
  schema: IValidateSchema,
  form: IForm | IAdminForm,
  errorSetter: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  const errorState: { [key: string]: boolean } = {};
  let isFormValid = true;
  Object.keys(schema).forEach((schemaField) => {
    const key = schemaField as keyof typeof form;
    if (!isFieldValid(schema, key, form)) {
      console.log(key);
      errorState[key] = true;
      isFormValid = false;
    }
  });

  errorSetter(errorState);
  return isFormValid;
};

export const validateField = (
  schema: IValidateSchema,
  key: keyof typeof form,
  form: IForm | IAdminForm,
  errors: { [key: string]: boolean },
  errorSetter: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  const errorState: { [key: string]: boolean } = {};
  errorState[key] = !isFieldValid(schema, key, form);
  errorSetter({ ...errors, ...errorState });
  console.log(key);
  console.log(errors);
};

export const isFieldValid = (
  schema: IValidateSchema,
  key: keyof typeof form,
  form: IForm | IAdminForm
): boolean => {
  if (!schema[key].required) return true;
  const fieldValue = form[key].toString();
  if (!fieldValue.length || parseFloat(fieldValue) === 0) return false;
  return true;
};
