import { IAdminForm } from '../types/Form';
import { IUser } from '../types/User';

// DODAJ SIE JEBANY PLIKU

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_DEV_URL,
  REACT_APP_API_VERSION,
} = process.env;

export const API_URL = `${
  process.env.NODE_ENV === 'production'
    ? REACT_APP_API_BASE_URL
    : REACT_APP_API_DEV_URL
}${REACT_APP_API_VERSION}`;

const CACHE_TIME = 0.5 * 60 * 1000;

class ApiHandler {
  static apiBaseUrl: string = API_URL as string;

  static async getMe() {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/me';
    try {
      const user = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseJSON = await user.json();
      if (!responseJSON) return false;
      return responseJSON;
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      return false;
    }
  }

  static async login(profile: { username: string; password: string }) {
    const apiRoute = ApiHandler.apiBaseUrl + '/auth/login/';
    try {
      const user = await fetch(apiRoute, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      });

      const responseJSON = await user.json();
      return responseJSON || false;
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
    }
  }

  static async logout() {
    const apiRoute = ApiHandler.apiBaseUrl + '/auth/logout/';
    try {
      const user = await fetch(apiRoute, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return user.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
    }
  }

  static async getUser(id: string): Promise<IUser> {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/' + id;
    try {
      const user = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return user.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('User not found');
    }
  }

  static async getUsers(query = '') {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/' + query;
    try {
      const users = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return users.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Users not found');
    }
  }

  static async getItems(path: string, option: string = '') {
    const apiRoute = ApiHandler.apiBaseUrl + '/' + path + '/' + option;
    try {
      const clients = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return clients.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject(`${path} not found`);
    }
  }

  static async createItem(path: string, value: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/' + path + '/';
    try {
      const form = await fetch(apiRoute, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: value }),
      });
      return form.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject(`Could not create ${path}`);
    }
  }

  static async editItem(path: string, id: string, value: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/' + path + '/' + id;
    try {
      const form = await fetch(apiRoute, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: value }),
      });
      return form.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Users not found');
    }
  }

  static async deleteItem(path: string, id: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/' + path + '/' + id;
    try {
      const res = await fetch(apiRoute, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject(`Failed to delete ${path}.`);
    }
  }

  static async getForms() {
    const apiRoute = ApiHandler.apiBaseUrl + '/forms/';
    try {
      const forms = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return forms.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Users not found');
    }
  }
  static async getForm(id: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/forms/' + id;
    try {
      const forms = await fetch(apiRoute, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return forms.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Users not found');
    }
  }
  // TODO ADD FORM INTERFACE
  static async createForm(body: IAdminForm) {
    const apiRoute = ApiHandler.apiBaseUrl + '/forms/';
    try {
      const form = await fetch(apiRoute, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return form.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Could not create form');
    }
  }

  static async updateForm(id: string, body: any) {
    const apiRoute = ApiHandler.apiBaseUrl + '/forms/' + id;
    try {
      const form = await fetch(apiRoute, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return form.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Users not found');
    }
  }

  static async deleteForm(id: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/forms/' + id;
    try {
      const res = await fetch(apiRoute, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Failed to delete form.');
    }
  }

  static async createUser(body: IUser) {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/';
    try {
      const res = await fetch(apiRoute, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return res.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Failed to create user.');
    }
  }

  static async editUser(id: string, body: any) {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/' + id;
    try {
      const form = await fetch(apiRoute, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return form.json();
    } catch (e) {
      console.log(e);
      return Promise.reject('Failed to edit user');
    }
  }

  static async deleteUser(id: string) {
    const apiRoute = ApiHandler.apiBaseUrl + '/users/' + id;
    try {
      const res = await fetch(apiRoute, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.json();
    } catch (e) {
      // TODO HANDLE ERRORS! POPUP?
      console.log(e);
      return Promise.reject('Failed to delete user.');
    }
  }
}

export default ApiHandler;
