import React, { useContext, useEffect, useState } from 'react';
import { IUser } from '../types/User';
import ApiHandler from '../utils/ApiHandler';
import UserContext from './UserContext';
import UsersContext from './UsersContext';

interface UsersContextProviderProps {
  children: any;
}

const CACHE_TIME = 0.5 * 60 * 1000;

export const UsersContextProvider: React.FC<UsersContextProviderProps> = ({
  children,
}) => {
  const user = useContext(UserContext);
  const [users, setUsers] = useState<IUser[] | null>(null);
  const [timestamp, setTimestamp] = useState<number>(0);

  const updateUsers = (force: boolean = false) => {
    try {
      const getUsers = async () => {
        console.log('FETCHING USERS');
        const res = await ApiHandler.getUsers();
        if (res.success) {
          console.log('SETTING USERS');
          setTimestamp(Date.now());
          setUsers(res.data);
        }
      };
      if (force || timestamp + CACHE_TIME < Date.now()) getUsers();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getUpdate = async () => {
      await updateUsers(true);
    };
    getUpdate();
  }, [user]);

  return (
    <UsersContext.Provider value={{ users, setUsers, updateUsers }}>
      {children}
    </UsersContext.Provider>
  );
};
