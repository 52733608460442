export enum idToType {
  '_id' = 'hidden',
  '__v' = 'hidden',
  'username' = 'disabled',
  'name' = 'text',
  'surname' = 'text',
  'additional' = 'text-multiline',
  'password' = 'password',
  'role' = 'role',
}

export enum idToLabel {
  'name' = 'Imię',
  'surname' = 'Nazwisko',
  'role' = 'Rola użytkownika',
  'username' = 'Nazwa użytkownika',
  'password' = 'Hasło',
  'additional' = 'Dodatkowe informacje',
}

export interface IUser {
  _id?: string;
  name: string;
  surname: string;
  role: string;
  username: string;
  password?: string;
  additional?: string;
}

export type idType = keyof typeof idToLabel;
