import { TextField } from '@material-ui/core';
import React from 'react';

export interface FormInputProps {
  id: string;
  type: string;
  value: string;
  label: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  error: boolean;
}

const FormTextInput: React.FC<FormInputProps> = ({
  type,
  id,
  value,
  label,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <TextField
      fullWidth
      error={error}
      helperText={error ? 'Pole jest wymagane.' : ' '}
      id={id}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      variant="outlined"
    />
  );
};

export default FormTextInput;
