import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  useContext,
} from 'react';
import { Redirect } from 'react-router-dom';
import api from '../utils/ApiHandler';
import UserContext from '../context/UserContext';
export interface AuthViewProps {
  [key: string]: string;
}

interface IFormValues {
  [key: string]: string;
  username: string;
  password: string;
}

const initialFormValues = { username: '', password: '' };

const AuthView: React.FC = () => {
  const [formValues, setFormValues] = useState<IFormValues>(initialFormValues);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formError, setFormError] = useState<boolean>(false);
  const user = useContext(UserContext);

  const redirectDestination = useRef('/form/list');

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const res = await api.login(formValues);
      if (res.success) {
        if (res.data.role === 'admin') {
          redirectDestination.current = '/admin/dashboard';
        }
        setIsAuthed(true);
        if (user.setUser) user.setUser(res.data);
      } else {
        setFormError(true);
      }
    } catch {
      setFormError(true);
    }
  };

  useEffect(() => {
    // check if user was logged in before (session) and authorize them if so
    const checkIfLoggedIn = async () => {
      if (user.user) return setIsAuthed(true);
      try {
        const res = await api.getMe();
        if (res && res?.success) {
          if (res.data.role === 'admin') {
            redirectDestination.current = '/admin/dashboard';
          }
          setIsAuthed(true);
          if (user.setUser) user.setUser(res.data);
        } else {
          setIsAuthed(false);
          if (user.setUser) user.setUser(null);
        }
        setIsLoading(false);
      } catch {
        setIsAuthed(false);
        if (user.setUser) user.setUser(null);
        setIsLoading(false);
      }
    };

    checkIfLoggedIn();
  }, []);

  const handleInputChange = (e: any, field: string) => {
    const prevState = { ...formValues };
    prevState[field] = e.target.value;
    setFormValues(prevState);
  };

  if (isLoading) return <LinearProgress />;
  if (isAuthed) return <Redirect to={redirectDestination.current} />;

  return (
    <Container maxWidth={'sm'}>
      <form onSubmit={handleLogin}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ textAlign: 'center', marginTop: '20vh' }}
        >
          Portal pracowników SzleszMar
        </Typography>
        <TextField
          fullWidth
          error={formError}
          style={{ marginTop: '2rem' }}
          value={formValues.username}
          onChange={(e) => handleInputChange(e, 'username')}
          label={'Nazwa użytkownika'}
          variant="outlined"
        />
        <TextField
          fullWidth
          error={formError}
          style={{ marginTop: '2rem' }}
          label={'Hasło'}
          value={formValues.password}
          onChange={(e) => handleInputChange(e, 'password')}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {formError && (
          <Alert severity="error">Logowanie nie powiodlo sie</Alert>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          style={{ marginTop: '2rem' }}
        >
          Zaloguj
        </Button>
      </form>
    </Container>
  );
};

export default AuthView;
