import React, { useState, useContext } from 'react';
import { TextField, Button, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ApiHandler from '../utils/ApiHandler';
import UserContext from '../context/UserContext';
export interface ChangePwProps {}

const fieldStyle = {
  marginTop: '1rem',
};

const ChangePw: React.FC<ChangePwProps> = () => {
  const user = useContext(UserContext);
  const [pw, setPw] = useState<string>('');
  const [rpw, setRPw] = useState<string>('');
  const [alertMsg, setAlertMsg] = useState<string>('');
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSuccess(false);
    if (pw.length < 5 || rpw.length < 5)
      return setAlertMsg('Hasło musi zawierać przynajmniej 5 znaków');
    if (pw !== rpw) return setAlertMsg('Hasła nie są takie same');
    if (!user.user) return setAlertMsg('Wystąpił błąd autoryzacji');
    try {
      const res = await ApiHandler.editUser(user.user._id!, {
        password: pw,
      });
      if (!res.success) return setAlertMsg('Nie udało się zmienić hasła');
      setSuccess(true);
      setAlertMsg('Zmieniono hasło');
    } catch (e) {
      setAlertMsg(e);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <TextField
          style={fieldStyle}
          fullWidth
          type="password"
          error={Boolean(!isSuccess && alertMsg.length)}
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          label="Nowe hasło"
        />
        <TextField
          style={fieldStyle}
          fullWidth
          type="password"
          error={Boolean(!isSuccess && alertMsg.length)}
          value={rpw}
          onChange={(e) => setRPw(e.target.value)}
          label="Powtórz hasło"
        />
        {alertMsg.length ? (
          <Alert severity={isSuccess ? 'success' : 'error'}>{alertMsg}</Alert>
        ) : null}
        <Button style={fieldStyle} type="submit" variant="contained">
          Wyslij
        </Button>
      </form>
    </Container>
  );
};

export default ChangePw;
