import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormsContext from '../context/FormsContext';
import UserContext from '../context/UserContext';
import UsersContext from '../context/UsersContext';
import api from '../utils/ApiHandler';
export interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
  const user = useContext(UserContext);
  const { setUsers } = useContext(UsersContext);
  const { setForms } = useContext(FormsContext);

  useEffect(() => {
    const logout = async () => {
      if (!user.setUser || !setUsers || !setForms) return;
      const res = await api.logout();
      if (!res.success) return;
      setForms(null);
      setUsers(null);
      user.setUser(null);
    };
    logout();
  });
  if (user.user) {
    return <>Loggin out</>;
  } else {
    return <Redirect to="/" />;
  }
};
export default Logout;
