import { createContext } from 'react';
import { IForm } from '../types/Form';

interface FormsContextObject {
  forms: IForm[] | null;
  setForms: React.Dispatch<React.SetStateAction<IForm[] | null>> | null;
  updateForms: ((force?: boolean) => void) | null;
}

const initialContext: FormsContextObject = {
  forms: null,
  setForms: null,
  updateForms: null,
};

const FormsContext = createContext(initialContext);

export default FormsContext;
