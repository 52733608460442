import { IUser } from './User';
import { IValidateSchema } from '../utils/validateForm';

export enum idToDriverType {
  '__v' = 'hidden',
  '_id' = 'hidden',
  'date' = 'date',
  'orderer' = 'client-search',
  'construction' = 'text',
  'cubicMetersAmount' = 'number',
  'drive' = 'number',
  'hoursOfWork' = 'number',
  'wash' = 'boolean',
  'kilometersAmount' = 'number',
  'pumpType' = 'text',
  'registrationNumber' = 'text',
  'driver' = 'hidden',
  'orderTime' = 'time',
  'orderedPump' = 'text',
  'pipelineAmount' = 'number',
  'specialConcreteAmount' = 'number',
  'arrivalTime' = 'time',
  'departureTime' = 'time',
  'pumpConfirmation' = 'signature',
  'additionalInformation' = 'text',
  'signature' = 'signature',
}

export enum idToAdminType {
  '__v' = 'hidden',
  '_id' = 'hidden',
  'date' = 'date',
  'orderer' = 'client-search',
  'construction' = 'text',
  'cubicMetersAmount' = 'hidden',
  'drive' = 'hidden',
  'hoursOfWork' = 'hidden',
  'wash' = 'hidden',
  'kilometersAmount' = 'hidden',
  'pumpType' = 'text',
  'registrationNumber' = 'text',
  'driver' = 'worker-search',
  'orderTime' = 'time',
  'orderedPump' = 'text',
  'pipelineAmount' = 'hidden',
  'specialConcreteAmount' = 'hidden',
  'arrivalTime' = 'hidden',
  'departureTime' = 'hidden',
  'pumpConfirmation' = 'hidden',
  'additionalDriverInformation' = 'hidden',
  'additionalSupervisorInformation' = 'hidden',
  'signature' = 'hidden',
}

export enum idToLabel {
  '_id' = 'id',
  'date' = 'Data',
  'orderer' = 'Zamawiający',
  'construction' = 'Budowa',
  'cubicMetersAmount' = 'Ilość m3',
  'drive' = 'Dojazd',
  'hoursOfWork' = 'Ilość godzin pracy',
  'wash' = 'Mycie',
  'kilometersAmount' = 'Ilość km',
  'pumpType' = 'Typ pompy',
  'registrationNumber' = 'Nr rej.',
  'driver' = 'Kierowca',
  'orderTime' = 'Godzina zamówienia',
  'orderedPump' = 'Zamówiona pompa',
  'pipelineAmount' = 'Rurociąg (stal/guma) ilość mb.',
  'specialConcreteAmount' = 'Betony specjalne ilość m3',
  'arrivalTime' = 'Godzina przyjazdu',
  'departureTime' = 'Godzina odjazdu',
  'pumpConfirmation' = 'Potwierdzenie pracy pompy',
  'additionalInformation' = 'Uwagi',
  'assignedUsersIds' = 'Pracownicy',
  'signature' = 'Podpis kierowcy',
}

export interface IAdminForm {
  date: Date;
  orderer: string;
  construction: string;
  pumpType: string;
  registrationNumber: string;
  driver: IUser;
  orderTime: Date;
  orderedPump: string;
}

export interface IForm extends IAdminForm {
  __v?: string;
  _id?: string;
  cubicMetersAmount?: number;
  drive?: number;
  hoursOfWork: number;
  wash: string;
  kilometersAmount: number;
  pipelineAmount: number;
  specialConcreteAmount?: number;
  arrivalTime: Date;
  departureTime: Date;
  pumpConfirmation: string;
  additionalDriverInformation?: string;
  additionalSupervisorInformation?: string;
  signature?: string;
}

export const adminFieldSchema: IValidateSchema = {
  date: {
    required: true,
  },
  orderer: {
    required: true,
  },
  construction: {
    required: true,
  },
  pumpType: {
    required: true,
  },
  registrationNumber: {
    required: true,
  },
  driver: {
    required: true,
  },
  orderTime: {
    required: true,
  },
  orderedPump: {
    required: true,
  },
};
export const userFieldSchema: IValidateSchema = {
  date: {
    required: true,
  },
  orderer: {
    required: true,
  },
  construction: {
    required: true,
  },
  pumpType: {
    required: true,
  },
  registrationNumber: {
    required: true,
  },
  driver: {
    required: true,
  },
  orderTime: {
    required: true,
  },
  orderedPump: {
    required: true,
  },
  cubicMetersAmount: {
    required: true,
  },
  drive: {
    required: true,
  },
  hoursOfWork: {
    required: true,
  },
  wash: {
    required: true,
  },
  kilometersAmount: {
    required: true,
  },
  pipelineAmount: {
    required: false,
  },
  specialConcreteAmount: {
    required: false,
  },
  arrivalTime: {
    required: true,
  },
  departureTime: {
    required: true,
  },
  pumpConfirmation: {
    required: true,
  },
  additionalDriverInformation: {
    required: false,
  },
  additionalSupervisorInformation: {
    required: false,
  },
};

export type idType = keyof typeof idToLabel;
