import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';

export interface FormInputProps {
  id: string;
  value: Date;
  label: string;
  onChange: (e: any) => void;
}

const FormDateInput: React.FC<FormInputProps> = ({
  id,
  value,
  label,
  onChange,
}) => {
  const today = new Date();
  const year = today.getFullYear();
  const month =
    today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
  const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();

  const [selectedDate, handleDateChange] = useState<Date | null>(
    value || new Date()
  );
  useEffect(() => {
    const syntheticEvent = {
      currentTarget: {
        id: id,
        value: selectedDate,
      },
    };
    onChange(syntheticEvent);
  }, [selectedDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <DatePicker
        id={id}
        format="dd.MM.yyyy"
        allowKeyboardControl
        label={label}
        value={value || new Date()}
        onChange={handleDateChange}
        showTodayButton
        todayLabel="Dzisiaj"
        cancelLabel="Anuluj"
        variant="dialog"
        inputVariant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormDateInput;
