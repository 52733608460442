import React, { useContext, useEffect, useState } from 'react';
import { IForm } from '../types/Form';
import ApiHandler from '../utils/ApiHandler';
import FormsContext from './FormsContext';
import UserContext from './UserContext';

interface FormsContextProviderProps {
  children: any;
}

const CACHE_TIME = 0.5 * 60 * 1000;

export const FormsContextProvider: React.FC<FormsContextProviderProps> = ({
  children,
}) => {
  const user = useContext(UserContext);
  const [forms, setForms] = useState<IForm[] | null>(null);
  const [timestamp, setTimestamp] = useState<number>(0);

  const updateForms = (force: boolean = false) => {
    try {
      const getForms = async () => {
        const res = await ApiHandler.getForms();
        if (res.success) {
          console.log('SETTING FORMS');
          setTimestamp(Date.now());
          setForms(res.data);
        }
      };
      if (force || timestamp + CACHE_TIME < Date.now()) getForms();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getUpdate = async () => {
      await updateForms(true);
    };
    console.log('updatinggg');
    getUpdate();
  }, [user]);

  return (
    <FormsContext.Provider value={{ forms, setForms, updateForms }}>
      {children}
    </FormsContext.Provider>
  );
};
