import { Button, Container, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { FormEvent, useEffect, useState, useContext } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import {
  adminFieldSchema,
  IAdminForm,
  idToAdminType,
  idToDriverType,
  idToLabel,
  IForm,
  userFieldSchema,
} from '../types/Form';
import ApiHandler from '../utils/ApiHandler';
import { validateForm } from '../utils/validateForm';
import FormsContext from '../context/FormsContext';

export interface FormProps {
  isAdmin?: boolean;
  newForm?: boolean;
}

interface IParams {
  formId: string;
}

const ReturnButton = () => (
  <Button
    variant="contained"
    size="large"
    color="primary"
    style={{ marginTop: '2rem' }}
    component={Link}
    to={'/admin/dashboard/'}
  >
    Powrót
  </Button>
);

const initialFormState: IForm = {
  date: new Date(),
  orderer: '',
  construction: '',
  cubicMetersAmount: 0,
  drive: 0,
  hoursOfWork: 0,
  wash: 'nie',
  kilometersAmount: 0,
  pumpType: '',
  registrationNumber: '',
  driver: {
    name: '',
    surname: '',
    role: 'user',
    username: '',
  },
  orderTime: new Date(),
  orderedPump: '',
  pipelineAmount: 0,
  specialConcreteAmount: 0,
  arrivalTime: new Date(),
  departureTime: new Date(),
  pumpConfirmation: '',
  additionalDriverInformation: '',
  additionalSupervisorInformation: '',

  signature: '',
};

const Form: React.FC<FormProps> = ({ isAdmin, newForm }) => {
  const { updateForms } = useContext(FormsContext);

  const { formId } = useParams<IParams>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [formState, setFormState] = useState<IForm | IAdminForm>(
    initialFormState
  );
  const [errorFields, setErrorFields] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    const getData = async () => {
      const res = await ApiHandler.getForm(formId);
      if (res.success) setFormState(res.data);
    };
    if (!newForm) getData();
  }, []);

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();
    try {
      let res;
      if (isAdmin) {
        validateForm(adminFieldSchema, formState, setErrorFields);
      } else {
        validateForm(userFieldSchema, formState, setErrorFields);
      }
      if (newForm) {
        res = await ApiHandler.createForm(formState);
      } else {
        res = await ApiHandler.updateForm(formId, formState);
      }
      if (!res.success) return;
      if (updateForms) await updateForms(true);
      setIsSuccess(true);
      setErrorMessage('');
    } catch (err) {
      setIsSuccess(false);
      setErrorMessage(err.message);
    }
  };

  if (isSuccess && isAdmin) return <Redirect to="/admin/dashboard" />;
  if (isSuccess && !isAdmin) return <Redirect to="/form/list" />;

  if (!formState) return <LinearProgress />;
  return (
    <Container>
      <form onSubmit={submitForm}>
        <ReturnButton />
        <FormContainer
          idToType={isAdmin ? idToAdminType : idToDriverType}
          idToLabel={idToLabel}
          formState={formState}
          setFormState={setFormState}
          errorFields={errorFields}
        />
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        <Button
          variant="contained"
          size="large"
          type="submit"
          color="primary"
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
        >
          Zapisz
        </Button>
      </form>
    </Container>
  );
};

export default Form;
