import {
  Button,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UsersContext from '../context/UsersContext';
import { idToLabel, idType, IUser } from '../types/User';
import ApiHandler from '../utils/ApiHandler';

const UserDataTable: React.FC = () => {
  const { users, setUsers, updateUsers } = useContext(UsersContext);

  const [results, setResults] = useState<IUser[]>([]);

  const deleteUser = async (id?: string) => {
    try {
      if (!id || !setUsers || !users) return;
      const res = await ApiHandler.deleteUser(id);
      if (res.success) setUsers(users.filter((user) => user._id !== id));
    } catch (err) {
      //setAlertMsg(err);
      console.error(err);
    }
  };

  const handleSearch = (e: any) => {
    if (!users) return;
    const query = e.target.value;
    const queryRegex = new RegExp(query, 'gi');
    if (query.length) {
      const filteredResults = users.filter((user) => {
        if (user.name.match(queryRegex) || user.surname.match(queryRegex)) {
          return user;
        }
      });
      setResults(filteredResults);
    } else {
      setResults(users);
    }
  };

  const handleForceUpdate = async () => {
    if (updateUsers) await updateUsers(true);
  };

  type sortFields = 'name' | 'surname' | 'role' | 'username';

  const handleSort = (field: sortFields) => {
    let filteredResults = [...results].sort((a, b) => {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
      return 0;
    });
    if (JSON.stringify(filteredResults) === JSON.stringify(results)) {
      filteredResults = [...results].sort((a, b) => {
        if (a[field] < b[field]) return 1;
        if (a[field] > b[field]) return -1;
        return 0;
      });
    }

    setResults(filteredResults);
  };

  useEffect(() => {
    if (!users) return;
    setResults(users);
  }, [users]);

  useEffect(() => {
    const update = async () => {
      if (updateUsers) await updateUsers();
    };
    update();
  }, []);

  if (!users) return <LinearProgress />;

  return (
    <TableContainer>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button component={Link} to={'/admin/user/new'}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Stwórz nowy" />
        </ListItem>
      </List>
      <Divider />
      <form
        style={{
          margin: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '2rem',
        }}
        noValidate
        autoComplete="off"
      >
        <Button onClick={handleForceUpdate}>
          <RefreshIcon />
        </Button>
        <TextField
          onChange={handleSearch}
          style={{
            width: '30ch',
          }}
          type="search"
          id="outlined-basic"
          label="Wyszukaj użytkownika"
        />
      </form>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('name')}
            >
              <ListItem button>
                {idToLabel['name' as idType]} {idToLabel['surname' as idType]}
              </ListItem>
            </TableCell>
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('username')}
            >
              <ListItem button>{idToLabel['username' as idType]}</ListItem>
            </TableCell>
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('role')}
            >
              <ListItem button>{idToLabel['role' as idType]}</ListItem>
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((user: IUser, rowIdx: number) => {
            return (
              <TableRow key={`row_${rowIdx}`}>
                <TableCell>
                  <ListItem>
                    <ListItemText primary={`${user.name} ${user.surname}`} />
                  </ListItem>
                </TableCell>
                <TableCell>
                  <ListItem>
                    <ListItemText primary={user.username} />
                  </ListItem>
                </TableCell>
                <TableCell>
                  <ListItem>
                    <ListItemText
                      primary={
                        user.role === 'admin' ? 'administrator' : 'pracownik'
                      }
                    />
                  </ListItem>
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  style={{ minWidth: '300px' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '1rem' }}
                    component={Link}
                    to={`/admin/user/${user._id}`}
                  >
                    Edytuj
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteUser(user._id)}
                  >
                    Usuń
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserDataTable;
