import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import UsersContext from '../context/UsersContext';
import { idToType, idToLabel, IUser } from '../types/User';
import ApiHandler from '../utils/ApiHandler';

const initialState: IUser = {
  name: '',
  role: 'user',
  surname: '',
  username: '',
  password: '',
  additional: '',
};

interface UserFormProps {
  newUser?: boolean;
}

interface IParams {
  userId: string;
}

const UserForm: React.FC<UserFormProps> = ({ newUser }) => {
  const { userId } = useParams<IParams>();
  const { users, setUsers } = useContext(UsersContext);
  const [formState, setFormState] = useState<IUser>(initialState);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (formState._id) return;
    if (newUser || !users) return;
    const user = users.find((user) => {
      return user._id === userId;
    });
    if (!user) return;

    setFormState(user);
  }, [userId, users, formState]);

  const handleChangePassword = (e: any) => {
    const prevState = { ...formState };
    if (!e.target.checked) {
      delete prevState.password;
    } else {
      prevState['password'] = e.target.value || '';
    }
    setFormState(prevState);
    setShowPasswordField(e.target.checked);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (newUser) return submitUser();
    editUser();
  };

  const submitUser = async () => {
    const user = formState;
    if (!user.password || user.password.length < 5)
      return setAlertMsg('Hasło musi mieć więcej niż 5 znaków');
    try {
      const newUser = { ...user, role: user.role };
      const res = await ApiHandler.createUser(newUser);
      console.log(res);
      if (res.success && users && setUsers) {
        delete newUser.password;
        setUsers([...users, newUser]);
        setIsSuccess(true);
      }
    } catch (err) {
      setAlertMsg(err);
      console.error(err);
    }
  };

  const editUser = async () => {
    try {
      const user = formState;
      if (!user._id || !setUsers || !users) return;
      if (user.username.length < 4)
        return setAlertMsg('Nazwa użytkownika musi mieć więcej niż 4 znaki');
      if (user.password && user.password.length < 5)
        return setAlertMsg('Hasło musi mieć więcej niż 5 znaków');
      const res = await ApiHandler.editUser(user._id, user);
      if (!res.success) return;
      const newState = users.map((curr) => {
        if (curr._id === user._id) {
          const newUser = res.data;
          delete newUser.password;
          return newUser;
        }
        return curr;
      });

      setUsers(newState);
      setIsSuccess(true);
    } catch (err) {
      setAlertMsg(err);
      console.error(err);
    }
  };

  useEffect(() => {
    const username =
      formState.name.charAt(0).toLowerCase() + formState.surname.toLowerCase();
    setFormState({ ...formState, username: username });
  }, [formState.surname, formState.name]);

  if (!users) return null;
  if (isSuccess) return <Redirect to={'/admin/dashboard/users'} />;

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{ marginTop: '2rem' }}
          component={Link}
          to={'/admin/dashboard/users'}
        >
          Powrót
        </Button>
        <FormContainer
          idToType={idToType}
          idToLabel={idToLabel}
          formState={formState}
          setFormState={setFormState}
        />
        {!newUser ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={showPasswordField}
                onChange={handleChangePassword}
                name="show-password-edit"
                color="primary"
              />
            }
            label="Zmień hasło"
          />
        ) : null}

        {alertMsg.length ? <Alert severity="error">{alertMsg}</Alert> : null}
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          style={{ margin: '2rem 0 2rem', width: '100%' }}
        >
          Zapisz
        </Button>
      </form>
    </Container>
  );
};

export default UserForm;
