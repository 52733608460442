import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export interface SignatureFieldProps {
  id: string;
  value: string;
  label: string;
  onChange: (e: any) => void;
  error: boolean;
  isDisabled?: boolean;
}

const SignatureField: React.FC<SignatureFieldProps> = ({
  id,
  value,
  label,
  onChange,
  error,
  isDisabled = false,
}) => {
  const [signatureUrl, setSignatureUrl] = useState<string | null>(value);
  const signCanvas = useRef<SignatureCanvas>(null);
  const [isCanvasOpen, setCanvasOpen] = useState(false);

  const handleOpenCanvas = () => {
    setCanvasOpen(!isCanvasOpen);
  };

  const handleCavnasSave = () => {
    handleOpenCanvas();
    onSaveImage();
  };

  const onSaveImage = async () => {
    const currentImg = signCanvas.current
      ? signCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      : '';
    await setSignatureUrl(currentImg);
    const syntheticEvent = {
      currentTarget: {
        id: id,
        value: currentImg,
      },
    };
    await onChange(syntheticEvent);
  };

  return (
    <>
      <Dialog fullWidth maxWidth={false} open={isCanvasOpen}>
        <SignatureCanvas
          ref={signCanvas}
          canvasProps={{
            width: window.innerWidth - 64,
            height: window.innerHeight - 150,
            style: {
              width: '100%',
              height: 'calc(100vh - 150px)',
            },
          }}
        />
        <DialogActions>
          <Button onClick={handleOpenCanvas} color="primary">
            Zamknij
          </Button>
          <Button onClick={handleCavnasSave} color="primary">
            Zapisz podpis
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        style={error ? { color: 'red' } : {}}
        variant="overline"
        display="block"
      >
        {label}
      </Typography>
      {signatureUrl || value ? (
        <img
          src={value || signatureUrl || ''}
          style={{ width: '250px' }}
          alt="Podpis pracownika"
        />
      ) : null}
      <div>
        {isDisabled ? null : signatureUrl ? (
          <Button
            size="large"
            color="primary"
            onClick={handleOpenCanvas}
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            Podpisz ponownie
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleOpenCanvas}
            style={{
              marginTop: '2rem',
              marginBottom: '1rem',
              border: error ? '1px solid red' : 'none',
            }}
          >
            Podpisz
          </Button>
        )}
      </div>
    </>
  );
};

export default SignatureField;
