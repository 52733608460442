import { createContext } from 'react';
import { IUser } from '../types/User';

interface UsersContextObject {
  users: IUser[] | null;
  setUsers: React.Dispatch<React.SetStateAction<IUser[] | null>> | null;
  updateUsers: ((force?: boolean) => void) | null;
}

const initialContext: UsersContextObject = {
  users: null,
  setUsers: null,
  updateUsers: null,
};

const UsersContext = createContext(initialContext);

export default UsersContext;
