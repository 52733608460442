import { Button, InputAdornment, TextField } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';

export interface FormInputProps {
  id: string;
  value: Date;
  label: string;
  onChange: (e: any) => void;
}

const FormTimeInput: React.FC<FormInputProps> = ({
  value,
  id,
  label,
  onChange,
}) => {
  const setCurrentTime = () => {
    const syntheticEvent = {
      currentTarget: {
        id: id,
        value: selectedDate,
      },
    };
    onChange(syntheticEvent);
  };

  const [selectedDate, handleDateChange] = useState<Date | null>(
    value || new Date()
  );

  useEffect(() => {
    setCurrentTime();
  }, [selectedDate]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <TimePicker
        id={id}
        label={label}
        value={value || new Date()}
        onChange={handleDateChange}
        showTodayButton
        ampm={false}
        todayLabel="Teraz"
        cancelLabel="Anuluj"
        variant="dialog"
        inputVariant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormTimeInput;
