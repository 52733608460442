import {
  Button,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormsContext from '../context/FormsContext';
import UsersContext from '../context/UsersContext';
import { idToLabel, idType } from '../types/Form';
import { IUser } from '../types/User';
import ApiHandler, { API_URL } from '../utils/ApiHandler';

interface ListViewProps {
  newLinkTo?: string;
}

interface FormListProps {
  adminView?: boolean;
}

const ListView: React.FC<ListViewProps> = ({ newLinkTo }) => {
  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          component={Link}
          to={newLinkTo ? newLinkTo : '/form/new'}
        >
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Stwórz nowy" />
        </ListItem>
      </List>
      <Divider />
      <FormList adminView={newLinkTo ? true : false} />
    </>
  );
};

export const FormList: React.FC<FormListProps> = ({ adminView = false }) => {
  const { users, updateUsers } = useContext(UsersContext);
  const { forms, setForms, updateForms } = useContext(FormsContext);

  const [results, setResults] = useState<any[]>([]);
  const [queryUser, setQueryUser] = useState<IUser | null>(null);

  const onDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    const res = await ApiHandler.deleteForm(id);
    if (!forms || !setForms) return;
    if (res.success) {
      setForms(forms.filter((forms) => forms._id !== id));
      setResults(results.filter((forms) => forms._id !== id));
    }
  };

  const handleForceUpdate = async () => {
    if (!updateUsers || !updateForms) return;
    await updateUsers(true);
    await updateForms(true);
  };

  const handleSort = (field: idType) => {
    let filteredResults = [...results].sort((a, b) => {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
      return 0;
    });
    if (JSON.stringify(filteredResults) === JSON.stringify(results)) {
      filteredResults = [...results].sort((a, b) => {
        if (a[field] < b[field]) return 1;
        if (a[field] > b[field]) return -1;
        return 0;
      });
    }

    setResults(filteredResults);
  };

  const handleSortUsers = (e: any, v: any) => {
    if (!users || !forms) return;
    let worker =
      users.find((worker) => v === `${worker.name} ${worker.surname}`) || null;
    if (v) {
      const filteredResults = forms.filter((task) => {
        if (worker && task.driver._id === worker._id) {
          return task;
        }
      });
      setResults(filteredResults);
    } else {
      setResults(forms);
    }
    setQueryUser(worker);
  };

  const handleSearch = (e: any) => {
    if (!forms) return;
    const query = e.target.value;
    const queryRegex = new RegExp(query, 'gi');
    if (query.length) {
      const filteredResults = forms.filter((task) => {
        if (
          task.orderer.match(queryRegex) ||
          task.construction.match(queryRegex)
        ) {
          return task;
        }
      });
      setResults(filteredResults);
    } else {
      setResults(forms);
    }
  };

  useEffect(() => {
    const update = async () => {
      if (!updateForms || !updateUsers) return;
      await updateUsers();
      await updateForms();
    };
    update();
  }, []);

  useEffect(() => {
    if (forms) setResults(forms);
  }, [forms]);

  if (!users || forms === null) return <LinearProgress />;
  if (!forms.length)
    return <Alert severity="warning">Lista zadań jest pusta</Alert>;
  return (
    <TableContainer>
      <div
        style={{
          margin: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '2rem',
        }}
      >
        <Button onClick={handleForceUpdate}>
          <RefreshIcon />
        </Button>
        <TextField
          onChange={handleSearch}
          type="search"
          style={{
            width: '30ch',
          }}
          id="outlined-basic"
          label="Wyszukaj zadania"
        />
        {adminView ? (
          <Autocomplete
            style={{
              width: '30ch',
            }}
            value={queryUser}
            inputValue={
              queryUser ? `${queryUser.name} ${queryUser.surname}` : ''
            }
            id={`list-search-user-${queryUser}`}
            options={users}
            onInputChange={handleSortUsers}
            getOptionLabel={(option) => `${option.name} ${option.surname}`}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Wyszukaj zadania użytkowników" />
              );
            }}
          />
        ) : null}
      </div>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('orderer')}
            >
              <ListItem button>
                {idToLabel['orderer' as idType]}/
                {idToLabel['construction' as idType]}
              </ListItem>
            </TableCell>
            {adminView && (
              <TableCell
                style={{ fontWeight: 'bold' }}
                onClick={() => handleSort('driver')}
              >
                <ListItem button>{idToLabel['driver' as idType]}</ListItem>
              </TableCell>
            )}
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('date')}
            >
              <ListItem button>Data</ListItem>
            </TableCell>
            <TableCell
              style={{ fontWeight: 'bold' }}
              onClick={() => handleSort('pumpConfirmation')}
            >
              <ListItem button>Podpisany</ListItem>
            </TableCell>
            {adminView && (
              <TableCell style={{ fontWeight: 'bold' }} align="right" />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((form) => {
            return (
              <TableRow key={form._id}>
                <TableCell>
                  <ListItem
                    component={Link}
                    to={`${adminView ? '/admin' : ''}/form/${form._id}`}
                  >
                    <ListItemText
                      primary={form.orderer}
                      secondary={form.construction}
                    />
                  </ListItem>
                </TableCell>
                {adminView && (
                  <TableCell>
                    {form.driver
                      ? `${form.driver.name} ${form.driver.surname}`
                      : null}
                  </TableCell>
                )}
                <TableCell align="left">
                  {form.date ? new Date(form.date).toLocaleDateString() : null}
                </TableCell>
                <TableCell align="left">
                  {form.pumpConfirmation.length ? (
                    <AssignmentTurnedInIcon style={{ marginLeft: '2.5rem' }} />
                  ) : null}
                </TableCell>
                {adminView && (
                  <TableCell align="right">
                    {adminView && form.pumpConfirmation.length ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '1rem', zIndex: 1 }}
                        href={API_URL + '/forms/download/' + form._id}
                        download
                      >
                        Pobierz PDF
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      style={{ margin: '1rem', zIndex: 1 }}
                      to={`/form/${form._id}`}
                    >
                      Edytuj
                    </Button>
                    {adminView ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: '1rem', zIndex: 1 }}
                        onClick={(e) => onDelete(e, form._id)}
                      >
                        Usuń
                      </Button>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListView;
