import {
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import UserContext from '../context/UserContext';
import FormArraySearch from '../components/NewFormInputs/FormArraySearch';
import FormItemSearch from '../components/NewFormInputs/FormItemSearch';
import FormDateInput from '../components/NewFormInputs/FormDateInput';
import FormTextInput from '../components/NewFormInputs/FormTextInput';
import FormTimeInput from '../components/NewFormInputs/FormTimeInput';
import FormWorkerSearch from '../components/NewFormInputs/FormWorkerSearch';
import FormSignatureInput from '../components/NewFormInputs/FormSignatureInput';

import {
  adminFieldSchema,
  IAdminForm,
  IForm,
  userFieldSchema,
} from '../types/Form';
import ApiHandler from '../utils/ApiHandler';
import FormsContext from '../context/FormsContext';
import { validateField, validateForm } from '../utils/validateForm';

const initialFormState: IForm = {
  date: new Date(),
  orderer: '',
  construction: '',
  cubicMetersAmount: 0,
  drive: 0,
  hoursOfWork: 0,
  wash: 'nie',
  kilometersAmount: 0,
  pumpType: '',
  registrationNumber: '',
  driver: {
    name: '',
    surname: '',
    role: 'user',
    username: '',
  },
  orderTime: new Date(),
  orderedPump: '',
  pipelineAmount: 0,
  specialConcreteAmount: 0,
  arrivalTime: new Date(),
  departureTime: new Date(),
  pumpConfirmation: '',
  additionalDriverInformation: '',
  additionalSupervisorInformation: '',
};

interface IParams {
  formId: string;
}

interface IPaginatedFormParams {
  newForm?: boolean;
}

type ErrorFields = { [key: string]: boolean };

const PaginatedForm: React.FC<IPaginatedFormParams> = ({ newForm }) => {
  const cachedFormState = newForm
    ? localStorage.getItem('lastFormState')
    : null;
  const currentFormState = cachedFormState
    ? JSON.parse(cachedFormState)
    : initialFormState;

  const user = useContext(UserContext);
  const { updateForms } = useContext(FormsContext);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [formState, setFormState] = useState<IForm>({
    ...currentFormState,
    driver: user.user!,
  });
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errorFields, setErrorFields] = useState<ErrorFields>({});
  const { formId } = useParams<IParams>();

  console.log(formState);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ApiHandler.getForm(formId);
        if (res.success) setFormState(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    if (!newForm) getData();
  }, []);

  useEffect(() => {
    if (!newForm) return;
    localStorage.setItem('lastFormState', JSON.stringify(formState));
  }, [formState]);

  const onNumberChange = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      const prevValue = formState[e.currentTarget.id as keyof IForm];
      const isZero =
        prevValue &&
        prevValue.toString().length === 1 &&
        !e.currentTarget.value;
      const number = isZero ? 0 : parseFloat(e.currentTarget.value);
      if (number || number === 0) {
        const field = { [e.currentTarget.id]: number };
        const newState: IForm = { ...formState, ...field };
        setFormState(newState);
      }
    },
    [formState]
  );

  const onTextChange = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      const field = { [e.currentTarget.id]: e.currentTarget.value };
      const newState: IForm = { ...formState, ...field };
      setFormState(newState);
    },
    [formState]
  );

  const onBlur = (e: React.FormEvent<HTMLSelectElement>) => {
    validateField(
      activeStep ? userFieldSchema : adminFieldSchema,
      e.currentTarget.id as keyof IAdminForm,
      formState,
      errorFields,
      setErrorFields
    );
  };

  const handleSubmit = async () => {
    const isValid = validateForm(userFieldSchema, formState, setErrorFields);
    console.log(isValid);
    if (!isValid) return;
    let res;
    if (newForm) {
      res = await ApiHandler.createForm(formState);
      if (updateForms) updateForms(true);
    } else {
      res = await ApiHandler.updateForm(formId, formState);
      if (updateForms) updateForms(true);
    }
    console.log(res);
    if (res.success) {
      setIsSuccess(true);
      localStorage.removeItem('lastFormState');
    }
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleNext = () => {
    const isValid = validateForm(adminFieldSchema, formState, setErrorFields);
    if (isValid) setActiveStep(1);
  };

  if (isSuccess)
    return (
      <Redirect
        to={`${
          user.user && user.user.role === 'admin'
            ? '/admin/dashboard'
            : '/form/list'
        }`}
      />
    );

  return (
    <Container>
      <Stepper activeStep={activeStep} style={{ marginTop: '1rem' }}>
        <Step>
          <StepLabel>1</StepLabel>
        </Step>
        <Step>
          <StepLabel>2</StepLabel>
        </Step>
      </Stepper>
      {activeStep ? (
        <>
          <WorkerFormStep
            onNumberChange={onNumberChange}
            onTextChange={onTextChange}
            formState={formState}
            setFormState={setFormState}
            errorFields={errorFields}
            onBlur={onBlur}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: '2rem' }}
          >
            Zakończ i wyślij
          </Button>
        </>
      ) : (
        <>
          <AdminFormStep
            onTextChange={onTextChange}
            formState={formState}
            setFormState={setFormState}
            errorFields={errorFields}
            onBlur={onBlur}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            style={{ marginTop: '2rem' }}
          >
            Dalej
          </Button>
        </>
      )}
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        style={{ marginTop: '2rem' }}
      >
        Cofnij
      </Button>
    </Container>
  );
};

interface IFormStepProps {
  onTextChange: any;
  formState: IForm;
  setFormState: React.Dispatch<React.SetStateAction<IForm>>;
  errorFields: ErrorFields;
  onBlur: (e: any) => void;
}

interface IWorkerStepProps extends IFormStepProps {
  onNumberChange: any;
}

const AdminFormStep: React.FC<IFormStepProps> = ({
  onTextChange,
  formState,
  errorFields,
  onBlur,
}) => {
  return (
    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
      <Grid item xs={6}>
        <FormDateInput
          id={'date'}
          value={formState.date}
          label="Data"
          onChange={onTextChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTimeInput
          id={'orderTime'}
          value={formState.orderTime}
          label="Czas zamówienia"
          onChange={onTextChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormItemSearch
          error={errorFields.orderer}
          id={'orderer'}
          value={formState.orderer}
          label="Zamawiający"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <FormItemSearch
          error={errorFields.construction}
          id={'construction'}
          value={formState.construction}
          label="Budowa"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          type="text"
          error={errorFields.pumpType}
          id={'pumpType'}
          value={formState.pumpType}
          label="Typ pompy"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          type="text"
          error={errorFields.orderedPump}
          id={'orderedPump'}
          value={formState.orderedPump}
          label="Zamówiona pompa"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          type="text"
          error={errorFields.registrationNumber}
          id={'registrationNumber'}
          value={formState.registrationNumber}
          label="Numer Rejestracyjny"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>

      <Grid item xs={12}>
        <FormWorkerSearch
          error={errorFields.driver}
          id={'driver'}
          value={formState.driver}
          label="Kierowca"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
    </Grid>
  );
};

const WorkerFormStep: React.FC<IWorkerStepProps> = ({
  onNumberChange,
  onTextChange,
  formState,
  errorFields,
  onBlur,
  setFormState,
}) => {
  useEffect(() => {
    const { arrivalTime, departureTime } = formState;
    if (!arrivalTime || !departureTime) return;
    const departureHours =
      new Date(departureTime).getHours() +
      new Date(departureTime).getMinutes() / 60;
    const arrivalHours =
      new Date(arrivalTime).getHours() +
      new Date(arrivalTime).getMinutes() / 60;
    const hoursOfWork =
      arrivalHours < departureHours
        ? Math.round((departureHours - arrivalHours) * 100) / 100
        : Math.round((24 - arrivalHours + departureHours) * 100) / 100;
    setFormState({ ...formState, hoursOfWork });
  }, [formState.arrivalTime, formState.departureTime]);

  return (
    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
      <Grid item xs={3}>
        <FormTextInput
          type="number"
          error={errorFields.cubicMetersAmount}
          id={'cubicMetersAmount'}
          value={`${formState.cubicMetersAmount}`}
          label="Ilość m3"
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          type="number"
          error={errorFields.drive}
          id={'drive'}
          value={`${formState.drive}`}
          label="Dojazd"
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          type="number"
          error={errorFields.hoursOfWork}
          id={'hoursOfWork'}
          value={`${formState.hoursOfWork}`}
          label="Czas pracy"
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          type="number"
          error={errorFields.kilometersAmount}
          id={'kilometersAmount'}
          value={`${formState.kilometersAmount}`}
          label="Ilość km"
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <FormArraySearch
          id={'wash'}
          options={['tak', 'nie']}
          value={`${formState.wash}`}
          label="Mycie na budowie"
          onChange={onTextChange}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          type="number"
          error={errorFields.pipelineAmount}
          id={'pipelineAmount'}
          value={`${formState.pipelineAmount}`}
          label="Rurociąg (stal/guma) ilość mb."
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          type="number"
          error={errorFields.specialConcreteAmount}
          id={'specialConcreteAmount'}
          value={`${formState.specialConcreteAmount}`}
          label="Przestawienie pompy (ilość)"
          onChange={onNumberChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTimeInput
          id={'arrivalTime'}
          value={formState.arrivalTime}
          label="Czas przybycia"
          onChange={onTextChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTimeInput
          id={'departureTime'}
          value={formState.departureTime}
          label="Czas wyjazdu"
          onChange={onTextChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          type="text"
          error={errorFields.additionalDriverInformation}
          id={'additionalDriverInformation'}
          value={`${formState.additionalDriverInformation}`}
          label="Uwagi kierowcy"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          type="text"
          error={errorFields.additionalSupervisorInformation}
          id={'additionalSupervisorInformation'}
          value={`${formState.additionalSupervisorInformation}`}
          label="Uwagi kierownika budowy"
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSignatureInput
          error={errorFields.pumpConfirmation}
          id={'pumpConfirmation'}
          value={`${formState.pumpConfirmation}`}
          label="Czytelny podpis klienta"
          onChange={onTextChange}
        />
      </Grid>
    </Grid>
  );
};

export default PaginatedForm;
