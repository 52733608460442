import React, { Dispatch, SetStateAction } from 'react';
import FormInput from './FormInput/FormInput';

//REVIEW enum type?
interface IFormProps<T> {
  formState: T;
  setFormState: Dispatch<SetStateAction<T>>;
  idToType: any;
  idToLabel: any;
  errorFields?: { [key: string]: boolean };
}

const FormContainer = <T extends object, U extends keyof T>({
  formState,
  setFormState,
  idToType,
  idToLabel,
  errorFields = {},
}: IFormProps<T>) => {
  return (
    <div>
      {Object.keys(formState).map((field) => {
        return (
          <FormInput
            errorFields={errorFields}
            key={field}
            idToType={idToType}
            idToLabel={idToLabel}
            formState={formState}
            setFormState={setFormState}
            value={formState[field as U]}
            id={field}
          />
        );
      })}
    </div>
  );
};

export default FormContainer;
