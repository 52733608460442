import React, { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import plLocale from 'date-fns/locale/pl';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { idType } from '../../types/Form';

export interface FormInputProps {
  value: Date;
  id: string;
  onChange: (e: any) => void;
  idToLabel: any;
  error: { [key: string]: boolean };
}

const FormTimeInput: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  idToLabel,
  error,
}) => {
  const setCurrentTime = () => {
    const syntheticEvent = {
      target: {
        id: id,
        value: selectedDate,
      },
    };
    onChange(syntheticEvent);
  };

  const [selectedDate, handleDateChange] = useState<Date | null>(
    value || new Date()
  );

  useEffect(() => {
    setCurrentTime();
  }, [selectedDate]);

  return (
    <Box width={1} style={{ marginTop: '2rem' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <TimePicker
          id={id}
          label={idToLabel[id as idType]}
          value={selectedDate}
          error={error[id]}
          onChange={(date) => date && handleDateChange(date)}
          ampm={false}
          showTodayButton
          todayLabel="Teraz"
          cancelLabel="Anuluj"
          variant="dialog"
          inputVariant="outlined"
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default FormTimeInput;
