import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { IUser } from '../../types/User';
import { idType } from '../../types/Form';
import ApiHandler from '../../utils/ApiHandler';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

export interface FormInputProps {
  value: recievedUser;
  id: string;
  onChange: (e: any) => void;
  idToLabel: any;
  error: { [key: string]: boolean };
}

interface recievedUser extends IUser {
  _id: string;
}

const FormWorkerSearch: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  idToLabel,
  error,
}) => {
  const [workers, setWorkers] = useState<recievedUser[]>([]);
  const [currentWorker, setCurrentWorker] = useState<recievedUser>(value);

  const handleInput = (e: any, v: any) => {
    let worker = workers.find(
      (worker) => v === `${worker.name} ${worker.surname}`
    )!;
    if (!worker) return;
    const syntheticEvent = {
      target: {
        id: id,
        value: worker,
      },
    };
    setCurrentWorker(worker);
    onChange(syntheticEvent);
  };
  useEffect(() => {
    const getData = async () => {
      const res = await ApiHandler.getUsers();
      if (res.success)
        setWorkers(res.data.filter((user: IUser) => user.role === 'user'));
    };
    getData();
  }, []);
  if (!workers) return null;
  return (
    <FormControl variant="outlined" fullWidth style={{ marginTop: '2rem' }}>
      <Autocomplete
        value={value}
        inputValue={
          currentWorker ? `${currentWorker.name} ${currentWorker.surname}` : ''
        }
        id={`form-workers-${value}`}
        options={workers}
        onInputChange={handleInput}
        getOptionLabel={(option) => `${option.name} ${option.surname}`}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error[id]}
              label={idToLabel[id as idType]}
              variant="outlined"
            />
          );
        }}
      />
    </FormControl>
  );
};

export default FormWorkerSearch;
