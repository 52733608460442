import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import React, { useEffect, useState } from 'react';
import { IUser } from '../../types/User';
import ApiHandler from '../../utils/ApiHandler';

export interface FormInputProps {
  id: string;
  value: IUser;
  label: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  error: boolean;
}

const FormWorkerSearch: React.FC<FormInputProps> = ({
  id,
  value,
  label,
  onChange,
  onBlur,
  error,
}) => {
  const [workers, setWorkers] = useState<IUser[]>([]);
  const [currentWorker, setCurrentWorker] = useState<IUser>(value);

  const handleInput = (e: any, v: any) => {
    let worker = workers.find(
      (worker) => v === `${worker.name} ${worker.surname}`
    )!;
    if (!worker) return;
    const syntheticEvent = {
      currentTarget: {
        id: id,
        value: worker,
      },
    };
    setCurrentWorker(worker);
    onChange(syntheticEvent);
  };
  useEffect(() => {
    const getData = async () => {
      const res = await ApiHandler.getUsers();
      if (res.success) setWorkers(res.data);
    };
    getData();
  }, []);
  if (!workers) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        value={value}
        inputValue={
          currentWorker ? `${currentWorker.name} ${currentWorker.surname}` : ''
        }
        id={id}
        options={workers}
        onInputChange={handleInput}
        getOptionLabel={(option) => `${option.name} ${option.surname}`}
        getOptionSelected={(option, value) => option._id === value._id}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onBlur={onBlur}
              error={error}
              label={label}
              variant="outlined"
            />
          );
        }}
      />
    </FormControl>
  );
};

export default FormWorkerSearch;
