import React from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { idType } from '../../types/Form';

export interface FormInputProps {
  value: boolean;
  id: string;
  onChange: (e: any) => void;
  idToLabel: any;
  error: { [key: string]: boolean };
}

const FormBooleanInput: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  idToLabel,
  error,
}) => {
  return (
    <FormControl
      error={error[id]}
      variant="outlined"
      fullWidth
      style={{ marginTop: '2rem' }}
    >
      <InputLabel id={`form-boolean-${value}`}>
        {idToLabel[id as idType]}
      </InputLabel>
      <Select
        native
        labelId={`form-boolean-${value}`}
        id="demo-simple-select-outlined"
        value={value}
        onChange={onChange}
        label={idToLabel[id as idType]}
        inputProps={{
          name: id,
          id: id,
        }}
      >
        <option value={'nie'}>Nie</option>
        <option value={'tak'}>Tak</option>
      </Select>
    </FormControl>
  );
};

export default FormBooleanInput;
