import {
  Button,
  Divider,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import ApiHandler from '../utils/ApiHandler';

interface SimpleListProps {
  itemPath: string;
}

interface IIsEdit {
  [key: string]: boolean;
}

interface IListItem {
  _id: string;
  name: string;
}

const SimpleList: React.FC<SimpleListProps> = ({ itemPath }) => {
  const [listData, setListData] = useState<IListItem[]>([]);
  const [isEdit, setIsEdit] = useState<IIsEdit>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [editInputValue, setEditInputValue] = useState<string>('');
  const [isEditAccepted, setIsEditAccepted] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await ApiHandler.getItems(itemPath);
        if (!res.success) return;
        setListData(res.data);
        const isEditObj: IIsEdit = {};
        res.data.forEach((item: IListItem) => {
          isEditObj[item._id] = false;
        });
        setIsEdit(isEditObj);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const onCreate = async () => {
    try {
      const valueToAdd = inputValue;
      const res = await ApiHandler.createItem(itemPath, valueToAdd);
      if (!res.success) return;
      setListData([res.data, ...listData]);
    } catch (err) {
      console.error(err);
    }
  };

  const onDelete = async (id: string) => {
    if (!id) return;
    const res = await ApiHandler.deleteItem(itemPath, id);
    if (!res.success) return;
    setListData(
      listData.filter((item) => {
        return item._id !== id;
      })
    );
  };

  const onEdit = (id: string, name: string) => {
    if (!isEditAccepted) return;
    const editObj: IIsEdit = {};
    Object.keys(isEdit).forEach((key) => {
      editObj[key] = key === id ? true : false;
    });
    setIsEditAccepted(false);
    setEditInputValue(name);
    setIsEdit(editObj);
  };

  const onAccept = async (id: string, name: string) => {
    if (name !== editInputValue) {
      const res = await ApiHandler.editItem(itemPath, id, editInputValue);
      if (!res.success) return;
      const updatedList = listData.map((item) => {
        return item.name === name ? res.data : item;
      });
      setListData(updatedList);
    }

    setIsEdit({ ...isEdit, [id]: false });
    setIsEditAccepted(true);
  };

  const onEditChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEditInputValue(e.currentTarget.value);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <List style={{ margin: '1rem' }}>
      <ListItem>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={inputValue}
          onChange={onChange}
        ></TextField>
        <Button
          variant="contained"
          size="large"
          type="submit"
          color="primary"
          onClick={onCreate}
          style={{ marginLeft: '1rem' }}
        >
          Stwórz
        </Button>
      </ListItem>
      {listData.map(({ name, _id }, idx) => {
        return (
          <div key={`item-${idx}`}>
            <Divider />

            <ListItem>
              {isEdit[_id] ? (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={editInputValue}
                  onChange={onEditChange}
                ></TextField>
              ) : (
                <Typography color="textPrimary">{name}</Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: '1rem 0 auto auto',
                  zIndex: 1,
                  border: !isEditAccepted && isEdit[_id] ? 'red 1px solid' : '',
                }}
                onClick={() =>
                  isEdit[_id] ? onAccept(_id, name) : onEdit(_id, name)
                }
              >
                {isEdit[_id] ? 'Akceptuj' : 'Edytuj'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '1rem', zIndex: 1 }}
                onClick={() => onDelete(_id)}
              >
                Usuń
              </Button>
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

export default SimpleList;
