import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export interface FormInputProps {
  id: string;
  options: string[];
  value: string;
  label: string;
  onChange: (e: any) => void;
}

const FormArraySearch: React.FC<FormInputProps> = ({
  id,
  options,
  value,
  label,
  onChange,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`form-label-${value}`}>{label}</InputLabel>
      <Select
        native
        labelId={`form-label-${value}`}
        id={id}
        value={value}
        onChange={onChange}
        label={label}
        inputProps={{
          name: id,
          id: id,
        }}
      >
        {options.map((option) => {
          return (
            <option value={option} key={option}>
              {option}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FormArraySearch;
