import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { idType } from '../../types/Form';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';

export interface FormInputProps {
  value: Date;
  id: string;
  onChange: (e: any) => void;
  idToLabel: any;

  error: { [key: string]: boolean };
}

const FormDateInput: React.FC<FormInputProps> = ({
  value,
  id,
  onChange,
  idToLabel,
  error,
}) => {
  const [selectedDate, handleDateChange] = useState<Date | null>(
    value || new Date()
  );
  useEffect(() => {
    const syntheticEvent = {
      target: {
        id: id,
        value: selectedDate,
      },
    };
    onChange(syntheticEvent);
  }, [selectedDate]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <DatePicker
          id={id}
          format="dd.MM.yyyy"
          error={error[id]}
          allowKeyboardControl
          style={{ marginTop: '2rem' }}
          label={idToLabel[id as idType]}
          value={selectedDate}
          onChange={(date) => date && handleDateChange(date)}
          showTodayButton
          todayLabel="Dzisiaj"
          cancelLabel="Anuluj"
          variant="dialog"
          inputVariant="outlined"
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default FormDateInput;
