import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from '../components/ProtectedRoute';
import Admin from '../screens/Admin';
import Auth from '../screens/Auth';
import Form from '../screens/Form';
import List from '../screens/List';
import Logout from '../screens/Logout';
import PaginatedForm from '../screens/PaginatedForm';
import ChangePassword from '../screens/ChangePassword';
import UserForm from '../screens/UserForm';

const RouterSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" component={Auth} />
      <Route exact path="/test" component={PaginatedForm} />
      <ProtectedRoute path="/newPw" roles={['user', 'admin']}>
        <ChangePassword />
      </ProtectedRoute>
      <ProtectedRoute path="/form/list/" roles={['user', 'admin']}>
        <List />
      </ProtectedRoute>
      <ProtectedRoute path="/form/new" roles={['admin', 'user']}>
        <PaginatedForm newForm />
      </ProtectedRoute>
      <ProtectedRoute path="/form/:formId" roles={['user', 'admin']}>
        <PaginatedForm />
      </ProtectedRoute>
      <Route path="/logout">
        <Logout />
      </Route>
      <ProtectedRoute path="/admin/dashboard/users" roles={['admin']}>
        <Admin usersView />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/dashboard" roles={['admin']}>
        <Admin />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/form/new" roles={['admin']}>
        <Form isAdmin newForm />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/user/new" roles={['admin']}>
        <UserForm newUser />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/form/:formId" roles={['admin']}>
        <Form isAdmin />
      </ProtectedRoute>
      <ProtectedRoute path="/admin/user/:userId" roles={['admin']}>
        <UserForm />
      </ProtectedRoute>
    </Switch>
  );
};

export default RouterSwitch;
