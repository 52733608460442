import React, { useContext, useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import ApiHandler from '../utils/ApiHandler';
import UserContext from '../context/UserContext';

export interface ProtectedRouteProps {
  children?: any;
  path: string;
  roles: string[];
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path,
  roles,
}) => {
  const user = useContext(UserContext);

  useEffect(() => {
    // if user is not set, but there is a session stored
    if (!user.user) {
      const getUserRole = async () => {
        const response = await ApiHandler.getMe();
        if (response.success) {
          if (user.setUser) {
            user.setUser(response.data);
          }
        }
      };
      getUserRole();
    }
  }, [user]);

  if (user.user && roles.includes(user.user.role))
    return (
      <Route exact path={path}>
        {children}
      </Route>
    );

  return (
    <Alert severity="error">
      Wystąpił błąd autoryzacji,
      <Link to="/">kliknij aby wrócić do ekranu głównego</Link>
    </Alert>
  );
};

export { ProtectedRoute };
