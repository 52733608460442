import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import ApiHandler from '../../utils/ApiHandler';

export interface FormInputProps {
  id: string;
  value: string;
  label: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  error: boolean;
}

const FormItemSearch: React.FC<FormInputProps> = ({
  id,
  value,
  label,
  onChange,
  onBlur,
  error,
}) => {
  const [items, setItems] = useState<string[]>([]);

  const handleInput = (e: any, v: any) => {
    const syntheticEvent = {
      currentTarget: {
        id: id,
        value: v,
      },
    };
    onChange(syntheticEvent);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ApiHandler.getItems(id + 's', 'parsed');
        if (res.success) setItems(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        id={id}
        value={value}
        freeSolo
        options={value ? items.filter((item) => item.includes(value)) : items}
        onInputChange={handleInput}
        autoComplete
        renderInput={(params) => {
          return (
            <TextField
              error={error}
              helperText={error ? 'Pole jest wymagane.' : ' '}
              {...params}
              label={label}
              onBlur={onBlur}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          );
        }}
      />
    </FormControl>
  );
};

export default FormItemSearch;
